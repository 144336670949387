import request from '@/utils/request'


// 查询钻石充值订单列表
export function listOrder(query) {
  return request({
    url: '/recharge/recharge-orders/list',
    method: 'get',
    params: query
  })
}

// 查询钻石充值订单分页
export function pageOrder(query) {
  return request({
    url: '/recharge/recharge-orders/page',
    method: 'get',
    params: query
  })
}


export function getOrderList(query) {
  return request({
    url: '/recharge/recharge-orders/getOrderList',
    method: 'get',
    params: query
  })
}

export function getUserDatas(query) {
  return request({
    url: '/user/user-distributors/pageDistributorData',
    method: 'get',
    params: query
  })
}

export function getSecondUserDatas(query) {
  return request({
    url: '/recharge/recharge-orders/getUserDatas',
    method: 'get',
    params: query
  })
}

// 查询钻石充值订单详细
export function getOrder(data) {
  return request({
    url: '/recharge/recharge-orders/detail',
    method: 'get',
    params: data
  })
}

// 新增钻石充值订单
export function addOrder(data) {
  return request({
    url: '/recharge/recharge-orders/add',
    method: 'post',
    data: data
  })
}

// 修改钻石充值订单
export function updateOrder(data) {
  return request({
    url: '/recharge/recharge-orders/edit',
    method: 'post',
    data: data
  })
}

// 删除钻石充值订单
export function delOrder(data) {
  return request({
    url: '/recharge/recharge-orders/delete',
    method: 'post',
    data: data
  })
}

export function getUserOrderList(data) {
  return request({
    url: '/recharge/recharge-orders/getUserOrderList',
    method: 'get',
    params: data
  })
}
